import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';

const Button = () => {
  const gitbookUrl = "http://Docs.bellmfb.com"; // Replace with your actual GitBook URL

  return (
    <Link to={gitbookUrl} target="_blank" rel="noopener noreferrer">
      <button className="flex items-center bg-[#003022] text-white font-semibold py-2 px-4 rounded hover:bg-[#00BC74] transition duration-300 w-full sm:w-auto">
        <FontAwesomeIcon icon={faBook} className="mr-2 hidden sm:block text-base" />
        <span className="text-xs sm:text-sm overflow-hidden whitespace-nowrap text-ellipsis max-w-[7ch] sm:max-w-none">
          API Documentation
        </span>
      </button>
    </Link>
  );
};

export default Button;

import React, { useEffect } from "react";
import Confetti from "react-confetti";  // Ensure react-confetti is installed
import { FaCheckCircle } from "react-icons/fa"; // Check icon
import { useWindowSize } from "react-use"; // For dynamic sizing
import { useNavigate } from "react-router-dom"; 

const KycSuccessPage = () => {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();

  // Automatically redirect to dashboard after a few seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/dashboard");
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white space-y-6 text-center">
      <Confetti width={width} height={height} />
      <div className="flex flex-col items-center space-y-4 p-8 bg-gray-100 rounded-xl shadow-lg">
        <FaCheckCircle className="text-green-600" size={90} />
        <h1 className="text-4xl font-extrabold text-gray-800">KYC Submitted Successfully!</h1>
        <p className="text-lg text-gray-500 max-w-md">
          Your KYC details have been submitted. You will be redirected to the dashboard shortly.
        </p>
      </div>
    </div>
  );
};

export default KycSuccessPage;

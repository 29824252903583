import React from 'react';
import classNames from 'classnames';
const Card = ({  className, children, style}) => {
    const cardClasses = classNames(
        className 
      );
  return (
    <div className={cardClasses} style={style}>{children}</div>
  )
}

export default Card
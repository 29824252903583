import React, { useState } from 'react'
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDown } from 'react-icons/fa';
import PasswordForm from './forms/PasswordForm';
import PinForm from './forms/PinForm';
const Security = () => {
  const ICONS_PATH = "/icons/"
  
  const [tab, setTab] = useState(1);


  const changeTab = (t) => {
    setTab(t);
  };
  return (
    <div className="bg-white w-full p-6">
        <div className="flex flex-col space-y-4">
        <div
         onClick={(t) => {changeTab(2)}}
          className="w-full h-full p-3 flex flex-row justify-between items-center hover:cursor-pointer">
           <div className="flex flex-row space-x-4">
            <div  className="bg-ash h-12 w-12 border flex justify-center items-center rounded-full cursor-pointer">
            <img src={`${ICONS_PATH}/password.svg`} alt="/password-svg" />
                  
            </div>
       <div className="flex flex-col space-y-1">
       <p className="text-md font-bold text-dark">Password</p>
       <div className="flex flex-col md:flex-row space-x-1">
    <p className="text-xs text-secondary">Manage your Account Password</p>
       </div>
       </div>
       </div>
       {tab === 2 ? (
        <div>
          <FaAngleDown size={20} className="text-dark" />
        </div>
       ): (
        <div>
            <FaAngleRight  size={20} className="text-dark" /> 
        </div> 
       )}
        
    </div>
    {tab === 2 && (
      <>
      <div className="py-6 bg-ash px-6 rounded-lg">
        <PasswordForm />
      </div>
      </>
    )}
    <div
     onClick={(t) => {changeTab(3)}} 
     className=" w-full h-full p-3 flex flex-row justify-between items-center hover:cursor-pointer">
    <div className="flex flex-row space-x-4">
            <div  className="bg-ash h-12 w-12 border flex justify-center items-center rounded-full cursor-pointer">
            <img src={`${ICONS_PATH}/pin.svg`} alt="/pin-svg" />
            </div>
       <div className="flex flex-col space-y-1">
       <p className="text-md font-bold text-dark">Transaction Pin</p>
       <div className="flex flex-col md:flex-row space-x-1">
    <p className="text-xs">Manage your 4 digit transaction pin</p>
       </div>
       </div>  
    </div>
    {tab === 3 ? (
        <div>
          <FaAngleDown size={20} className="text-dark" />
        </div>
       ): (
        <div>
            <FaAngleRight  size={20} className="text-dark" /> 
        </div> 
       )} 
    </div>
    {tab === 3 && (
      <>
      <div className="py-6 bg-ash px-6 rounded-lg">
        <PinForm />
      </div>
      </>
    )}
        </div>
        </div>
  )
}


export default Security
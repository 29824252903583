const Card = ({ icon, heading, description, balance }) => {
    return (
      <div className="bg-white shadow-lg rounded-lg p-6 flex items-center space-x-6 transition-transform duration-300 hover:scale-105 hover:shadow-xl">
        <div className="flex-shrink-0 bg-gradient-to-r from-[#D8FFF0] to-[#D8FFF0] p-4 rounded-full">
          <img src={icon} alt={heading} className="w-12 h-12" />
        </div>
        <div>
          <h4 className="text-lg font-semibold text-[#003022]">{heading}</h4>
          <p className="text-sm text-gray-500">{description}</p>
          <h5 className="text-2xl font-bold text-[#00BC74] mt-2">{balance}</h5>
        </div>
      </div>
    );
  };
  
  export default Card;
  
// import React from 'react'

import TransactionChart from "./TransactionChart"

const Statistics = () => {
  return (
    <>
    <div className="w-full h-full bg-white mx-auto p-4 rounded-xl">
        <TransactionChart  />
    </div>
    </>
  )
}


export default Statistics
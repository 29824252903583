
import Login from '../../pages/authentication/login';
import SignUp from '../../pages/authentication/signup/SignUp';
import ForgotPassword from "../../pages/authentication/forget-password/ForgotPassword";
import KycReview from 'pages/authentication/KycReview';
// import KYCForm from 'pages/kyc/KYCForm';


let authRoutes = [
  {
    path: `/`,
    component: Login,
    protected: false
  },
  // {
  //   path: `/kyc`,
  //   component: KYCForm,
  //   protected: false
  // },
  {
    path: `/kyc-review`,
    component: KycReview,
    protected: true
  },
  {
    path: `/signup`,
    component:SignUp,
    protected: false
  },
  {
    path: `/forgot-password`,
    component: ForgotPassword,
    protected: false
  },

 
];

export default authRoutes;

import React from 'react'
import CollectionTable from './Components/Table'

const Collections = () => {
  return (
    <div className="p-6">
        <div className="py-4">
            <p className="text-xl text-dark font-semibold">Payment Collections</p>
        </div>
        <div className="py-6 bg-white w-full h-screen rounded-xl">
        <CollectionTable />
        </div>
        
    </div>
  )
}

export default Collections
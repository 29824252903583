import { useState } from "react";

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState("Dashboard");
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState("");

  const Menus = [
    { title: "Dashboard", src: "dashboard" },
    { title: "Transactions", src: "transactions" },
    { title: "Collections", src: "Collections" },
    {
      title: "Pay Out",
      src: "transfer",
      submenu: true,
      submenuItems: [
        { title: "New Payout", src: "transfer" },
        { title: "Payout History", src: "history" },
      ],
    },
    { title: "Accounts", src: "Account" },
    { title: "Settings", src: "Setting" },
  ];

  const handleMenuClick = (menu) => {
    setActiveMenu(menu.title);
    if (menu.submenu) {
      setSubmenuOpen(!submenuOpen);
    }
  };

  const handleSubmenuClick = (submenu) => {
    setActiveSubmenu(submenu.title);
  };

  return (
    <div className="flex">
      {/* Fixed Sidebar */}
      <div
        className={`${
          open ? "w-72" : "w-20"
        } bg-white h-screen fixed top-0 left-0 p-5 pt-8 transition-all duration-300`}
      >
        <img
          src="/control.png"
          alt="open"
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple border-2 rounded-full ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <img
            src="/icon.png"
            alt="bellbank MFB"
            className={`cursor-pointer duration-500 ${open && "rotate-[360deg]"}`}
          />
          <h1
            className={`text-primary font-bold origin-left text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            BellBank MFB
          </h1>
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <li key={index} className="mt-3">
              <div
                className={`flex items-center p-2 cursor-pointer rounded-md hover:bg-gray-100 text-primary text-sm font-semibold gap-x-4 
                  ${activeMenu === Menu.title && "bg-gray-300 text-darkPrimary"} 
                  ${Menu.submenu && submenuOpen && "bg-lightPrimary"}`}
                onClick={() => handleMenuClick(Menu)}
              >
                <img src={`/${Menu.src}.png`} alt="menu"/>
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-200`}
                >
                  {Menu.title}
                </span>
              </div>
              {Menu.submenu && submenuOpen && open && (
                <ul className="pl-12">
                  {Menu.submenuItems.map((submenuItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={`text-primary text-sm font-semibold flex items-center p-2 cursor-pointer rounded-md hover:bg-gray-200 
                        ${activeSubmenu === submenuItem.title && "bg-gray-300 text-darkPrimary"}`}
                      onClick={() => handleSubmenuClick(submenuItem)}
                    >
                      <img src={`/${submenuItem.src}.png`} alt="sub-menu" />
                      <span className="ml-2">{submenuItem.title}</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content Area */}
      <div className={`flex-1 ml-${open ? '72' : '0'} transition-all duration-300 p-5`}>
        {/* Content goes here */}
      </div>
    </div>
  );
};

export default Sidebar;

import React from 'react';

// Import icons (replace with actual icons as needed)
import bankIcon from '../../../assets/icons/bank.svg'; 
import userIcon from '../../../assets/icons/userr.svg'; 
import accountIcon from '../../../assets/icons/virtual-acct.svg'; 

const AccountDetails = () => {
  return (
    <div className="bg-white p-2 md:p-3 rounded-lg shadow-md border hover:shadow-lg  transition-transform duration-300 hover:scale-105 hover:shadow-xl">
      <h3 className="text-lg md:text-xl font-bold text-[#003022] mb-2 border-b pb-1">Account Details</h3>
      <div className="space-y-3">
        <div className="flex items-center space-x-3 bg-[#D8FFF0] p-1 rounded-md">
          <img src={bankIcon} alt="Bank Icon" className="w-5 h-5 md:w-6 md:h-6" />
          <div>
            <p className="text-xs md:text-sm text-gray-500">Bank:</p>
            <p className="font-semibold text-sm text-[#003022]">BellBank MFB</p>
          </div>
        </div>
        <div className="flex items-center space-x-3 bg-[#D8FFF0] p-1 rounded-md">
          <img src={userIcon} alt="User Icon" className="w-5 h-5 md:w-6 md:h-6" />
          <div>
            <p className="text-xs md:text-sm text-gray-500">Account Holder:</p>
            <p className="font-semibold text-sm text-[#003022]">John Doe</p>
          </div>
        </div>
        <div className="flex items-center space-x-3 bg-[#D8FFF0] p-1 rounded-md">
          <img src={accountIcon} alt="Account Icon" className="w-5 h-5 md:w-6 md:h-6" />
          <div>
            <p className="text-xs md:text-sm text-gray-500">Account No:</p>
            <p className="font-semibold text-sm text-[#003022]">0123456789</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;

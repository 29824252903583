// src/pages/components/AuthLayout.jsx
import React from 'react';
import Logo from '../shared/logo/logo';
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from './components/Slider';

const IMG_PATH = "/img/";

const AuthLayout = ({ children }) => {
  return (
    <>
      <ToastContainer />
      <div className="flex h-screen bg-white">
        <div className="hidden lg:block w-1/2 bg-gray-100 overflow-hidden">
          <Slider /> 
        </div>
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col h-full justify-center mt-20">
            <div className="flex justify-center items-center">
              <Logo />
            </div>
            <div className="mx-6 md:mx-48 lg:mx-32">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;

import React from "react";
// import ApiKeysTable from "./Components/Table";
import Form from "./forms/Form";
import { FaBook } from 'react-icons/fa'; // Import the desired icon
import ComingSoonButton from "pages/components/navbar/components/ComingSoonButton";

const ApiKeys = () => {
  return (
    <div className="">
      <div className="flex flex-col lg:flex-row items-center mb-4">
        <p className="text-[16px] text-dark font-semibold lg:mr-[47%] mb-2 lg:mb-0">
          API Keys Applications
        </p>
        <ComingSoonButton />
        {/* <a
          href="http://Docs.bellmfb.com" // Replace with your GitBook URL
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center px-4 py-2 text-white bg-[#003022] rounded-md shadow-lg hover:bg-[#00BC74] transition duration-300 ease-in-out transform hover:scale-105"
        >
          <FaBook className="mr-2" /> 
          View Documentation
        </a> */}
      </div>
      <div className="p-6 bg-white rounded-lg ">
        {/* <ApiKeysTable /> */}
        <Form />
      </div>
    </div>
  );
};

export default ApiKeys;

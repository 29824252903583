import React from 'react';
import Logo from '../shared/logo/logo';
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const IMG_PATH = "/img/";

const KycLayout = ({ children }) => {
  return (
    <>
      <ToastContainer />
      <div className="flex h-screen bg-white">
        <div className="hidden lg:block w-1/4 bg-dark overflow-hidden"> {/* Changed to w-1/4 */}
          <div className="bg-[#F0F0F0] w-full h-full flex flex-col justify-center items-center p-8">
            <img className="py-12" src='/img/kyc.png' alt="/img" />
            <h2 className="text-2xl font-semibold text-[#003022]">KYC Process</h2>
            <p className="text-[14px] text-center text-gray-700">Complete your KYC to proceed with our services.</p>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col h-full justify-center mt-20">
            <div className="flex justify-center items-center">
              <Logo />
            </div>
            <div className="mx-6  ">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

KycLayout.propTypes = {
  children: PropTypes.node,
};

export default KycLayout;

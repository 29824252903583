import React, { useEffect, useRef, useState } from 'react';
import Toggle from "./components/Toggle";
import UserDropDown from "./components/UserDropDown";
import Notification from "./components/Notification";
import TransactionPin from '../../transactionPin/index';
import OtpModal from "../../../components/modal/OtpModal";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const userState = useSelector((state) => state.user);
  const kycStatus = userState.KycBusinesses && userState.KycBusinesses[0] ? userState.KycBusinesses[0].status : null;
  const userPin = userState.User?.pin;
  const pin = localStorage.getItem('pinSet');

  const [isModalUOpen, setIsModalUOpen] = useState(false);

  const handleUOpen = () => {
    setIsModalUOpen(true);
  };

  const handleModalUClose = () => setIsModalUOpen(false);

  useEffect(() => {
    if (kycStatus === "approved" && userPin === null && pin) {
      handleUOpen();
    }
  }, [userPin, kycStatus, pin]);

  // Close the user dropdown if clicked outside
  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsUserDropdownOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  return (
    <div className="absolute left-0 right-0  bg-white shadow-sm p-4 z-[-10]"> {/* Set z-index here */}
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row justify-start items-center space-x-3">
          {/* Optional: Add a title or logo here */}
          {/* <h1 className="text-xl font-bold">Your App Name</h1> */}
        </div>
        <div className="flex flex-row space-x-5 items-center md:justify-end">
          <Toggle />
          <Notification dropNotidownRef={dropdownRef} isNotificationDropdownOpen={false} />
          <UserDropDown dropdownRef={dropdownRef} isUserDropdownOpen={isUserDropdownOpen} />
        </div>
      </div>

      <OtpModal title="Set Transaction Pin" open={isModalUOpen} onClose={handleModalUClose} width={400} height={350}>
        <TransactionPin onClose={handleModalUClose} />
      </OtpModal>
    </div>
  );
};

export default Navbar;

// src/utils/routes/kycRoutes.js
// import KYCForm from "pages/kyc/KYCForm";
import KycForm from "pages/kyc/forms/KycForm";

const kycRoutes = [
  {
    path: '/kyc-new',
    component: KycForm,
  },
  // Add more KYC-related routes here if needed
];

export default kycRoutes;

import Util from "../utils/util";
import { http } from "../utils/http";

// Function to get the server type from localStorage
const getServerType = () => localStorage.getItem("server") || "live"; // Default to 'live' if not set

// Function to get the correct base URL based on the server type
const getBaseUrl = () => {
  const serverType = getServerType();
  if (serverType === "test") {
    return process.env.REACT_APP_TEST_URL; // Use test URL from environment variable
  }
  return process.env.REACT_APP_LIVE_URL; // Use live URL from environment variable
};

let accountService = {
  getMyAccount: async () => {
    const url = `${getBaseUrl()}business/account/me`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  
  createIndividualAccount: async (body) => {
    try {
      const url = `${getBaseUrl()}business/account/clients/individual`;
      const response = await http.post(url, body, Util.getRegisterHeaders());

      if (response && response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false, error: "Error while creating virtual account" };
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return { success: false, error: error.response.data.message };
      } else {
        return { success: false, error: "An unexpected error occurred" };
      }
    }
  },

  createCorporateAccount: async (body) => {
    try {
      const url = `${getBaseUrl()}business/account/clients/corporate`;
      const response = await http.post(url, body, Util.getRegisterHeaders());

      if (response && response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false, error: "Error while creating virtual account" };
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return { success: false, error: error.response.data.message };
      } else {
        return { success: false, error: "An unexpected error occurred" };
      }
    }
  },

  getCorporateAccounts: async (page) => {
    const url = `${getBaseUrl()}business/account/clients?limit=${Util.limit}&page=${page}&accountType=corporate`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  
  getIndividualAccounts: async (page) => {
    const url = `${getBaseUrl()}business/account/clients?limit=${Util.limit}&page=${page}&accountType=individual`;
    return await http.get(url, Util.getRegisterHeaders());
  }
};

export { accountService };

// import React from "react";
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogContent,
	//   DialogTitle,
	//   IconButton,
	//   useMediaQuery,
} from '@mui/material';
// import { Close } from "@mui/icons-material";

const OtpModal = ({ open, onClose, title, children, width, height }) => {
	//   const isMobile = useMediaQuery("(max-width: 600px)");

	return (
		<Dialog
			open={open}
			// onClose={onClose}
			PaperProps={{
				style: {
					borderRadius: '10px',
					position: 'relative',
				},
			}}
			maxWidth={false}
			fullWidth={false}
		>
			{/* <DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: isMobile ? "14px" : "20px",
            top: isMobile ? `calc(-8px + 2%)` : "6px",
            zIndex: 1300,
            backgroundColor: "#E4AA3D",
            color: "#fff",
            borderRadius: "30%", // Circular box for the close icon
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              backgroundColor: "#E4AA3D",
              color: "#d1fae5",
            },
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle> */}

			<DialogContent
				sx={{
					width,
					height,
					overflow: 'auto',
					'&::-webkit-scrollbar': {
						display: 'none', // Hide the scrollbar
					},
				}}
			>
				<div className="flex items-center justify-between">
					<p className="text-xl font-bold text-dark">{title}</p>
				</div>
				{children}
			</DialogContent>
		</Dialog>
	);
};

OtpModal.propTypes = {
	title: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

OtpModal.defaultProps = {
	width: 'auto', // Default width
	height: 'auto', // Default height
};

export default OtpModal;

import React, { useState, useEffect } from 'react'; 
import { useNavigate } from 'react-router-dom'; 
import OtpModal from "../../../../components/modal/OtpModal";
import ServerForm from "./ServerForm";

const Toggle = () => {
  const [isChecked, setIsChecked] = useState(false); 
  const [initialState, setInitialState] = useState(false); 
  const [isModalUOpen, setIsModalUOpen] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    const serverValue = localStorage.getItem('server');
    if (serverValue !== null) {
      setIsChecked(serverValue === 'true'); 
    }
  }, []); // Only run once on component mount

  const handleToggle = () => {
    setInitialState(isChecked); // Save the initial state before toggling
    setIsModalUOpen(true); // Open the modal for confirmation
  };

  // Handle closing of the OTP modal and revert toggle if needed
  const handleModalUClose = (revert) => {
    if (revert) {
      // Revert to the previous state if needed
      setIsChecked(initialState);
    }
    setIsModalUOpen(false); // Close modal
  };

  return (
    <div className="flex items-center space-x-4">
      <span className={`text-sm font-semibold ${isChecked ? 'text-primary' : 'text-red'}`}>
        {isChecked ? 'Live' : 'Test'}
      </span>
      <button
        className={`${isChecked ? 'bg-primary' : 'bg-secondary'} w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out transform`}
        onClick={handleToggle}
      >
        <span
          className={`${isChecked ? 'translate-x-6' : 'translate-x-0'} inline-block w-4 h-4 bg-white rounded-full shadow-md transform duration-300 ease-in-out`}
        />
      </button>
      <OtpModal title="Mode Activation" open={isModalUOpen} onClose={() => handleModalUClose(true)} width={400} height={200}>
        <ServerForm onClose={(revert) => handleModalUClose(revert)} currentMode={isChecked} navigate={navigate} />
      </OtpModal>
    </div>
  );
};

export default Toggle;

// import React from 'react'
import Card from "../../../components/cards/Card";
import BlackButton from "../../../components/controls/BlackButton";
import Statistics from "./Statistics"
import { Link } from 'react-router-dom';

const DashboardBody = () => {
  const IMG_PATH = "/icons/"
  return (
    <div>
       <div className="flex flex-col space-y-3">
      <div>
        <p className="text-dark text-xl font-semibold">Quick Actions</p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-4">
      <Card className="bg-white rounded-xl p-4">
      <Link to="/interbank-transfer" className="flex flex-col space-y-5 items-start"> {/* Add Link here */}
        <div className="py-3">
          <img className="w-full" src={`${IMG_PATH}dash-green.svg`} alt="/icon" />
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-dark text-lg font-semibold">Payout (Interbank Transfer)</p> {/* Updated heading */}
          <p className="text-secondary text-sm font-regular">Explore different methods of transferring funds between banks.</p> {/* Updated description */}
        </div>
      </Link>
    </Card>

    <Card className="bg-white rounded-xl p-4">
      <Link to="/transactions" className="flex flex-col space-y-5 items-start">
        <div className="py-3">
          <img className="w-full" src={`${IMG_PATH}transaction.svg`} alt="/icon" />
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-dark text-lg font-semibold">Transactions</p> {/* Updated heading */}
          <p className="text-secondary text-sm font-regular">Manage and track your transactions with ease.</p> {/* Updated description */}
        </div>
      </Link>
    </Card>


    <Card className="bg-white rounded-xl p-4">
  <Link to="/collections-history" className="flex flex-col space-y-5 items-start">
    <div className="py-3">
      <img className="w-full" src={`${IMG_PATH}dash-yellow.svg`} alt="/icon" />
    </div>
    <div className="flex flex-col space-y-2">
      <p className="text-dark text-lg font-semibold">Collection</p> {/* Updated heading */}
      <p className="text-secondary text-sm font-regular">Easily manage and track collections from customers.</p> {/* Updated description */}
    </div>
  </Link>
    </Card>


    <Card className="bg-white rounded-xl p-4">
      <Link to="/virtual-account" className="flex flex-col space-y-5 items-start">
        <div className="py-3">
          <img className="w-full" src={`${IMG_PATH}virtual-acct.svg`} alt="/icon" />
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-dark text-lg font-semibold">Virtual Account</p> {/* Updated heading */}
          <p className="text-secondary text-sm font-regular">Manage your transactions seamlessly with a virtual account.</p> {/* Updated description */}
        </div>
      </Link>
    </Card>

      </div>
     
      <Card className="bg-white rounded-xl relative">
      <div className="flex flex-col md:flex-row space-y-4 md:space-x-10 md:space-y-0">
    <div className="flex flex-col items-center md:items-start justify-center md:justify-start space-y-2 p-4">
      <p className="text-dark font-semibold text-2xl text-center md:text-left">Send & Receive Money <span className="font-medium">Seamlessly</span></p>
      <p className="text-secondary text-md text-center md:text-left">Confirm payment and manage sales.</p>
      <div className="py-4">
        <BlackButton>Get Started</BlackButton>
      </div>
    </div>
    <div className="md:absolute flex justify-center items-center bottom-0 md:right-6 md:mt-0">
      <img className="w-48 md:w-52" src={`${IMG_PATH}dash-lady.svg`} alt="/lady-icon" />
    </div>
  </div>
</Card>

    </div>
        <div className="my-5"><Statistics /></div>
     
    </div>
  )
}

export default DashboardBody
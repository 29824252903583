import React, {useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'; 

// Register the necessary components
Chart.register(...registerables);

const TransactionChart = () => {
  const [selectedFilter, setSelectedFilter] = useState('none'); // State for selected filter

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value); // Update selected filter when dropdown value changes
    // Here, you can update the chart data based on the selectedFilter value
    // For brevity, let's assume the chartData updates according to selectedFilter
    // You need to implement the logic for updating data based on the selectedFilter
  };
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Collection',
        backgroundColor: 'rgba(255, 165, 0, 0.5)', // Orange color for Collection
        borderColor: 'rgba(255, 165, 0, 1)',
        borderWidth: 1,
        data: [65, 59, 80, 81, 56, 55, 40, 70, 60, 45, 50, 75], // Sample data for Collection
      },
      {
        label: 'Outward',
        backgroundColor: 'rgba(75, 192, 192, 0.5)', // Another color for Outward
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: [45, 55, 70, 60, 40, 35, 50, 65, 70, 55, 60, 80], // Sample data for Outward
      },
    ],
  };

  const options = {
    scales: {
      yAxis: [{
        ticks: {
          beginAtZero: true,
        },
      }],
      xAxis: [{
        offset: true,
      }],
    },
  };
  

  return (
    <div className="flex flex-col items-center w-full px-4"> {/* Full-width, centered with padding */}
    <div className="w-full max-w-screen-lg"> {/* Limit the maximum width */}
      <div className="flex justify-end mb-4"> {/* Margin bottom for select input */}
        <select className="p-2 rounded-md border" value={selectedFilter} onChange={handleFilterChange}>
          <option value="none">No Filter</option>
          <option value="last7">Last 7 Days</option>
          <option value="last15">Last 15 Days</option>
          <option value="last30">Last 30 Days</option>
          <option value="last365">Last 365 Days</option>
        </select>
      </div>
      <div className="w-full flex justify-center"> {/* Center the chart */}
        <div className="w-full"> {/* Adjust chart width (3/4 of the screen width on medium screens) */}
          <h2 className="text-center">Bar Chart</h2>
          <div className="w-full lg:w-full md:max-w-5xl flex justify-center items-center">
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default TransactionChart;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setServer } from "../../../../store/api-session/apiSlice";
import { toast } from "react-toastify";
import SubmitButton from "../../../../components/controls/SubmitButton";
import { authService } from 'services/auth.service'; // Import the service to fetch profile data
import "react-toastify/dist/ReactToastify.css";

const ServerForm = ({ onClose, currentMode, navigate }) => {
  const [isChecked, setIsChecked] = useState(currentMode); // Use the current mode passed from the Toggle component
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsChecked(currentMode); // Sync the state with the current mode when it changes
  }, [currentMode]);

  const handleToggle = async () => {
    if (!isChecked) { // Trying to switch to live mode
      try {
        const result = await authService.getProfile();
        const fetchedKycStatus = result?.data?.business?.BaasKyc?.status;
        const fetchedApiKeyStatus = result?.data?.business?.allowLiveApiKey;

        console.log("KYC Status:", fetchedKycStatus);
        console.log("Allow Live API Key:", fetchedApiKeyStatus);

        // Check if KYC is approved and live API key is allowed
        if (fetchedKycStatus === "approved" && fetchedApiKeyStatus) {
          setIsChecked(true); // Allow switching to live mode
          dispatch(setServer(true)); // Set the server mode in Redux
          localStorage.setItem("server", 'true'); // Save live mode state
          window.location.reload(); // Refresh the page to apply changes
          onClose(false); // Close the modal without reverting
        } else {
          // Redirect based on the KYC status if not approved
          if (fetchedKycStatus === 'not_submitted') {
            navigate('/kyc'); // Redirect to KYC submission page
          } else if (fetchedKycStatus === 'rejected') {
            navigate('/kyc'); // Redirect to KYC page for rejected status
          } else if (fetchedKycStatus === 'pending') {
            navigate('/kyc-review'); // Redirect to KYC review page
          } else {
            console.error("Error: Invalid KYC status.");
          }
        }
      } catch (error) {
        console.error("Failed to fetch KYC status:", error);
      }
    } else {
      // Switching to test mode
      setIsChecked(false); 
      dispatch(setServer(false)); // Set the server mode in Redux
      localStorage.setItem("server", 'false'); // Save test mode state
      window.location.reload(); // Refresh the page to apply changes
      onClose(false); // Close the modal without reverting
    }
  };

  return (
    <div className="flex flex-col space-y-4 py-3">
      <p className="text-secondary text-sm">
        You are about to switch to{" "}
        <span className="uppercase font-bold">{isChecked ? "LIVE MODE" : "TEST MODE"}</span>. Kindly note that
        this will change your base URL and will fetch fresh data.
      </p>
      <div className="py-3 flex flex-row gap-8 justify-between">
        <SubmitButton onClick={() => onClose(true)} bgColor="bg-red/25" textColor="text-red">
          Cancel
        </SubmitButton>
        <SubmitButton onClick={handleToggle} bgColor="bg-green/25" textColor="text-green">
          Continue
        </SubmitButton>
      </div>
    </div>
  );
};

export default ServerForm;

import React from 'react'
import TransactionTable from './components/TransactionTable'

const BusinessTransactions = () => {
  return (
    <div className="p-3">
        <div className="py-4 flex flex-col space-y-3">
            <p className="text-xl text-dark font-semibold">Transaction</p>
            <p className="text-dark text-sm">See all transaction and insight into each transaction</p>
        </div>
        <div className="py-6 bg-white w-full h-full rounded-xl">
        <TransactionTable />
        </div>
        
    </div>
  )
}

export default BusinessTransactions
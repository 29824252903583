import React from "react";
import PropTypes from "prop-types";

const BlackButton = ({
  children,
  onClick,
  bgColor,
  textColor,
  disabled,
}) => {
  return (
    <button
      className={`w-32 h-12 rounded-xl px-3 cursor-pointer ${bgColor} ${textColor}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

BlackButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
};

BlackButton.defaultProps = {
  bgColor: "bg-Primary",
  textColor: "text-white",
  disabled: false,
};

export default BlackButton;

import InputField from '../../../components/controls/InputField';
import SelectField from '../../../components/controls/SelectField';
import SubmitButton from '../../../components/controls/SubmitButton';
import { TbCurrencyNaira } from 'react-icons/tb';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { transferService } from '../../../services/transfer.service';
import OtpModal from '../../../components/modal/OtpModal';
import ClipLoader from 'react-spinners/ClipLoader';
import OtpForm from './OtpForm';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const NewPayout = () => {
	const [form, setForm] = React.useState({});

	const navigate = useNavigate();

	const handleFormChange = React.useCallback((name, value) => {
		setForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}, []);

	const { wallet } = useSelector((state) => state.wallet);
	const [token, setToken] = useState('');
	const [banks, setBanks] = useState([]);
	const [loading, setLoading] = useState(null);
	const [acctInfo, setAcctInfo] = useState(undefined);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isModalUOpen, setIsModalUOpen] = useState(false);

	React.useEffect(() => {
		const getAllBanks = () => {
			transferService.getBanks(35).then((result) => {
				setBanks(result.data.data);
			});
		};

		getAllBanks();
	}, []);

	const getAccountInfo = React.useCallback(async (payload) => {
		try {
			setLoading(true);
			const response = await transferService.getAccountInfo(payload);

			if (response.data.success) {
				setAcctInfo(response.data.data);
			} else {
				toast.error(
					`${response.data.message}, the Otp expires in 10 minutes..`
				);
			}
		} catch (e) {
			toast.error(e.response?.data.message ?? e.message);
		} finally {
			setLoading(false);
		}
	}, []);

	React.useEffect(() => {
		if (
			form?.bankCode &&
			form?.accountNumber &&
			form?.accountNumber.length > 9
		) {
			getAccountInfo({
				bankCode: form.bankCode,
				accountNumber: form.accountNumber,
			});
		}
	}, [getAccountInfo, form?.bankCode, form?.accountNumber]);

	const requestBankTransfer = React.useCallback(async (form) => {
		try {
			setIsProcessing(true);
			const response = await transferService.transferRequest(form);
			if (response.data?.success) {
				setToken(response.data.token);
				setIsModalUOpen(true);
			} else {
				toast.error(
					response.data.message || 'Unable to generate transfer request.'
				);
			}
		} catch (error) {
			toast.error(error.response?.data.message ?? error.message);
		} finally {
			setIsProcessing(false);
		}
	}, []);

	const approveBankTransfer = React.useCallback(
		async (form) => {
			try {
				setIsProcessing(true);
				const response = await transferService.transferApproved(form);
				if (response.data?.success) {
					toast.success(response.data.message);
					navigate('/success');
				} else {
					toast.error(response?.data?.message);
				}
			} catch (error) {
				toast.error(
					error.response?.data?.message ||
						error?.message ||
						'Could not approve bank transfer'
				);
			} finally {
				setIsProcessing(false);
			}
		},
		[navigate]
	);

	const handleFormValidation = React.useCallback(
		(form) => {
			const errors = [];

			if (!form?.bankCode) errors.push('Please select a bank.');
			if (!form?.amount || isNaN(+form.amount))
				errors.push('Amount is not valid.');
			if (!form?.accountNumber || !acctInfo?.accountName)
				errors.push('No Bank Account was found.');

			if (errors.length > 0) {
				errors.forEach((error) => {
					toast.error(error);
				});
				return false;
			}

			return true;
		},
		[acctInfo]
	);

	const handleSubmit = React.useCallback(
		(form) => {
			const isValid = handleFormValidation(form);

			if (isValid) {
				requestBankTransfer({
					...form,
					remark: form.remark || 'Bank Transfer',
				});
			}
		},
		[handleFormValidation, requestBankTransfer]
	);

	return (
		<div className="flex flex-col space-y-4 py-4 px-8">
			<div className="w-full">
				<SelectField
					name="bankCode"
					id="bankCode"
					label="Bank Name"
					value={form?.bankCode}
					onChange={(e) => {
						handleFormChange('bankCode', e.target.value);
					}}
				>
					<option value="" key="default">
						Bank Name
					</option>
					{banks.map((bank, index) => (
						<option
							key={index}
							className="dark:bg-white"
							value={bank.institutionCode}
						>
							{bank.institutionName}
						</option>
					))}
				</SelectField>
			</div>
			<div>
				<InputField
					value={form?.accountNumber}
					type="number"
					label="Account Number"
					name="accountNumber"
					onChange={(e) => {
						const value = e.target.value;
						handleFormChange('accountNumber', value);
						// if (/^\d{0,11}$/.test(value)) {
						// 	handleFormChange(value);
						// }
					}}
				/>
				{loading ? (
					<div className="flex justify-start w-full h-full">
						<ClipLoader color="#E4AA3D" size={40} data-testid="loader" />
					</div>
				) : (
					<span className="text-primary text-md capitalize py-4">
						<p>{acctInfo?.accountName ?? 'Reciever Name'}</p>
					</span>
				)}
			</div>

			<div>
				<InputField
					value={form?.amount}
					type="number"
					label="Amount"
					name="amount"
					onChange={(e) => {
						handleFormChange('amount', e.target.value);
					}}
					leftIcon={<TbCurrencyNaira size={20} />}
				/>
				<div className="flex flex-row justify-between py-3">
					<div className={`text-primary text-md flex justify-end`}>
						₦{wallet?.availableBalance ?? '0.00'}
					</div>
				</div>
			</div>
			<div>
				<InputField
					value={form?.remark}
					type="text"
					label="Remark"
					name="remark"
					onChange={(e) => {
						handleFormChange('remark', e.target.value);
					}}
				/>
			</div>
			<div className="my-2">
				<SubmitButton onClick={() => handleSubmit(form)}>
					<span className="text-white font-semibold">
						{isProcessing ? (
							<ClipLoader color="#fff" size={30} data-testid="loader" />
						) : (
							'Pay'
						)}
					</span>
				</SubmitButton>
			</div>
			<OtpModal
				title="Verify OTP"
				open={isModalUOpen}
				onClose={() => setIsModalUOpen(false)}
				width={400}
				// height={350}
			>
				<OtpForm
					isProcessing={isProcessing}
					onSubmit={(form) => {
						approveBankTransfer(form);
					}}
					token={token}
					reSend={() => handleSubmit(form)}
					onClose={() => setIsModalUOpen(false)}
				/>
			</OtpModal>
		</div>
	);
};

export default NewPayout;

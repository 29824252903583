import Util from "../utils/util";
import { http } from "../utils/http";

// Function to get the server type from localStorage
const getServerType = () => localStorage.getItem("server") || "live"; // Default to 'live' if not set

// Function to get the correct base URL based on the server type
const getBaseUrl = () => {
  const serverType = getServerType();
  if (serverType === "test") {
    return process.env.REACT_APP_TEST_URL; // Use test URL from environment variable
  }
  return process.env.REACT_APP_LIVE_URL; // Use live URL from environment variable
};

let authService = {
  login: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/login`, body, Util.getHeaders());
  },
  
  verifyLoginOtp: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/login/verify-otp`, body, Util.getHeaders());
  },
  
  register: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/register`, body, Util.getHeaders());
  },
  
  verifyEmailOtp: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/email/verify`, body, Util.getRegisterHeaders());
  },
  
  verifyPhoneOtp: async (body) => {
    return await http.post(`${getBaseUrl()}auth/phone/verify`, body, Util.getRegisterHeaders());
  },
  
  completeProfile: async (body) => {
    return await http.put(`${getBaseUrl()}auth/business/profile`, body, Util.getRegisterHeaders());
  },
  
  setPassword: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/password`, body, Util.getRegisterHeaders());
  },
  
  forgotPassword: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/forgot-password`, body, Util.getHeaders());
  },
  
  verifyForgotPassword: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/forgot-password/verify`, body, Util.getRegisterHeaders());
  },
  
  resetPassword: async (body) => {
    return await http.put(`${getBaseUrl()}auth/business/reset-password`, body, Util.getRegisterHeaders());
  },
  
  changePassword: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/password/change`, body, Util.getRegisterHeaders());
  },
  
  resendOtp: async () => {
    return await http.get(`${getBaseUrl()}auth/business/register/resend-otp`, Util.getRegisterHeaders());
  },
  
  getProfile: async () => {
    return await http.get(`${getBaseUrl()}auth/business/me`, Util.getRegisterHeaders());
  },
  
  setPin: async (body) => {
    return await http.post(`${getBaseUrl()}auth/business/pin`, body, Util.getRegisterHeaders());
  },
};

export { authService };

import React from 'react';

const WelcomeBanner = () => {
  return (
    <div className="relative bg-white p-8 rounded-lg shadow-lg h-48 lg:h-60 flex items-center justify-between">
      {/* Welcome Message */}
      <div className="z-10 flex-1">
        <h2 className="text-primary text-3xl font-bold mb-2">Welcome Back!</h2>
        <p className="text-dark text-[14px] max-w-md">
          Manage your finances effortlessly with your dashboard. Check account details, track your transactions, and more.
        </p>
      </div>

      {/* Decorative Image */}
      <div className="absolute lg:right-[10px]">
        <img
          src="/img/welcome-banner.png"  
          alt="Welcome Illustration"
          className="w-60 h-60 lg:w-50 lg:h-50 object-contain"
        />
      </div>

      {/* Decorative Shapes */}
      <div className="absolute bottom-[-10px] left-[-5px] w-20 h-20 bg-primary rounded-full"></div>
      <div className="absolute top-[-20px] left-[-10px] w-10 h-10 bg-darkPrimary rounded-full"></div>
    </div>
  );
};

export default WelcomeBanner;

import React from "react";
import { FaRegHourglass } from "react-icons/fa";


const IMG_PATH = "/img/";
const KycReviewPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full text-center">
        <div className="flex flex-col items-center space-y-4">
          {/* Illustration */}
          <img
            src={`${IMG_PATH}kyc.png`}
            alt="KYC Review Illustration"
            className="w-45 h-45"
          />

          {/* Icon */}
          <FaRegHourglass size={50} className="text-[#00BC74]" />

          {/* Heading */}
          <h1 className="text-3xl font-bold text-[#003022]">
            KYC Under Review
          </h1>

          {/* Description */}
          <p className="text-gray-600 text-sm">
            Thank you for submitting your KYC information. We are currently reviewing your documents.
          </p>
          <p className="text-gray-600 text-sm">
            You will be notified once the review is complete. This usually takes up to 48 hours.
          </p>
        </div>

        {/* Button */}
        <div className="mt-6">
          <button
            onClick={() => window.location.href = "/dashboard"}
            className="text-white bg-[#00BC74] hover:bg-[#009d60] px-6 py-3 rounded-md font-semibold transition duration-200"
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default KycReviewPage;

import React, { useEffect } from "react";
import Confetti from "react-confetti";  // Make sure to install react-confetti: npm install react-confetti
import { FaCheckCircle } from "react-icons/fa"; // Corrected import
import { useWindowSize } from "react-use"; // Ensure react-use is installed
import { useNavigate } from "react-router-dom"; 

const SuccessPage = () => {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();

  // Automatically redirect to dashboard after a few seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/dashboard");
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-lightGray space-y-4 text-center">
      <Confetti width={width} height={height} />
      <FaCheckCircle className="text-primary" size={80} />
      <h1 className="text-3xl font-bold text-primary">Payment Successful!</h1>
      <p className="text-md text-gray-600">
        Your payment has been processed successfully. You will be redirected to the dashboard shortly.
      </p>
    </div>
  );
};

export default SuccessPage;

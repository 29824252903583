import Util from "../utils/util";
import { http } from "../utils/http";

// Function to get the server type from localStorage
const getServerType = () => localStorage.getItem("server") || "live"; // Default to 'live' if not set

// Function to get the correct base URL based on the server type
const getBaseUrl = () => {
  const serverType = getServerType();
  if (serverType === "test") {
    return `${Util.getTestUrl}business/sandbox/`; // Use test URL with sandbox path
  }
  return `${Util.getLiveUrl}business/`; // Use live URL with business path
};

let transferService = {
  getBanks: async () => {
    const url = `${getBaseUrl()}transfer/banks`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getCharges: async (amount) => {
    const url = `${getBaseUrl()}transactions/charges/amount?amount=${amount}&transactionType=bank_transfer`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getAccountInfo: async (body) => {
    const url = `${getBaseUrl()}transfer/name-enquiry`;
    return await http.post(url, body, Util.getRegisterHeaders());
  },
  transferRequest: async (body) => {
    const url = `${getBaseUrl()}transfer/request`;
    return await http.post(url, body, Util.getRegisterHeaders());
  },
  transferApproved: async (body) => {
    const url = `${getBaseUrl()}transfer/approve`;
    return await http.post(url, body, Util.getRegisterHeaders());
  },
  getAllTransfer: async () => {
    const url = `${getBaseUrl()}transfer`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getTransferById: async (id) => {
    const url = `${getBaseUrl()}transfer/${id}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getTransferByReference: async (reference) => {
    const url = `${getBaseUrl()}transfer/reference/${reference}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getTransferByBeneficiaries: async () => {
    const url = `${getBaseUrl()}transfer/beneficiaries`;
    return await http.get(url, Util.getRegisterHeaders());
  },
};

export { transferService };

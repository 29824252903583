import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SlickSlider.css'; // Use the CSS below for animation

const slides = [
  {
    image: '/img/slide1.png',
    title: 'Make Transactions Effortlessly',
    description: 'Experience seamless transactions using virtual accounts.',
  },
  {
    image: '/img/slide2.png',
    title: 'Secure and Reliable',
    description: 'Your transactions are safe with advanced security features.',
  },
  {
    image: '/img/slide3.png',
    title: '24/7 Access',
    description: 'Manage your transactions anytime, anywhere.',
  },
  {
    image: '/img/slide4.png',
    title: 'User-Friendly Interface',
    description: 'Navigate through your banking needs with ease.',
  },
];

const SlickSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    beforeChange: (current, next) => setActiveSlide(next), // Track the active slide
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <Slider {...settings} className="w-full max-w-md">
        {slides.map((slide, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center text-center"
          >
            <div className="flex justify-center items-center w-full">
              <img
                src={slide.image}
                alt={slide.title}
                className="w-[70%] h-[70%] object-cover"
              />
            </div>
            <h2 className="text-lg font-semibold mt-1">
              {slide.title}
            </h2>
            <p
              className={`text-gray-600 mt-1 w-full ${
                activeSlide === index ? 'typing-animation' : ''
              }`}
            >
              {slide.description}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlickSlider;

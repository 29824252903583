import React, { useState } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import SubmitButton from '../../../../components/controls/SubmitButton';

const PinForm = () => {
  const [pinDigits, setPinDigits] = useState(['', '', '', '']);
  const pinInputs = Array.from({ length: 4 });

  const handleNumberClick = (number) => {
    const newPinDigits = [...pinDigits];
    const emptyIndex = newPinDigits.findIndex((digit) => digit === '');
    if (emptyIndex !== -1) {
      newPinDigits[emptyIndex] = number.toString();
      setPinDigits(newPinDigits);
    }
  };

  const handleArrowClick = () => {
    const lastFilledIndex = pinDigits.slice().reverse().findIndex((digit) => digit !== '');
    if (lastFilledIndex !== -1) {
      const newPinDigits = [...pinDigits];
      const actualIndex = pinDigits.length - 1 - lastFilledIndex;
      newPinDigits[actualIndex] = '';
      setPinDigits(newPinDigits);
    }
  };
  
  const handleButtonClick = () => {
    const enteredPin = pinDigits.join('');
    if (enteredPin.length === 4) {
      console.log('Entered PIN:', enteredPin);
      // Proceed with further actions
    } else {
      console.error('Please enter a valid PIN.');
    }
  };

  return (
    <div className="px-4">
      <p className="text-dark text-center md:text-start py-3">Enter your old transaction pin</p>
      <div className="w-[320px] lg:w-[400px] h-full border border-ash px-2 shadow-sm rounded-xl py-5">
        <div className="w-full h-full p-3 bg-lig rounded-xl flex justify-between items-center">
          {pinDigits.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              className="w-12 h-12 text-2xl text-center border  rounded-lg focus:border-primary"
              onChange={(e) => {
                const newPinDigits = [...pinDigits];
                newPinDigits[index] = e.target.value.slice(-1);
                setPinDigits(newPinDigits);
                if (e.target.value === '' && index > 0) {
                  pinInputs[index - 1].focus();
                }
              }}
              ref={(el) => (pinInputs[index] = el)}
            />
          ))}
        </div>
        <div className="py-4">
          <div className="grid grid-cols-3 text-2xl text-dark gap-4">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, '00', 0].map((number) => (
              <div
                key={number}
                className="flex justify-center items-center cursor-pointer"
                onClick={() => handleNumberClick(number.toString())}
              >
                <p>{number}</p>
              </div>
            ))}
            <div className="flex justify-center items-center cursor-pointer" onClick={handleArrowClick}>
              <FaArrowLeftLong size={15} className="text-dark" />
            </div>
          </div>
          <div className="py-4">
            <SubmitButton onClick={handleButtonClick} disabled={false}>
              Proceed
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PinForm;

import React from 'react';

const ComingSoonButton = () => {
  return (
    <div className="relative inline-block border-dotted border-grey rounded-md p-1">
      <button className="flex items-center justify-center bg-gray-300 text-gray-500 font-semibold py-2 px-4 rounded cursor-not-allowed opacity-75">
        <span className="text-xs sm:text-sm">Request API Access</span>
      </button>
      <span className="absolute -top-1 -right-2 bg-[#00BC74] text-white text-[10px] font-semibold py-1 px-2 rounded-full">
        Coming Soon
      </span>
    </div>
  );
};

export default ComingSoonButton;

import React, { useState, useEffect, useCallback } from "react";
import { apikeyService } from "../../../services/apikey.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../../../usehook/useAuth";
import { FaCopy } from "react-icons/fa";
import { webhookService } from "../../../services/webhook.service";
import { authService } from "services/auth.service";
import { storage } from "utils/storage";

const Form = () => {
  const { logout } = useAuth();
  const [data, setData] = useState({
    consumerSecret: storage.get("consumerSecret") || "",
    consumerKey: storage.get("consumerKey") || "",
    sandboxSecret: storage.get("sandboxSecret") || "",
    sandboxKey: storage.get("sandboxKey") || "",
  });

  const [loading, setLoading] = useState(false); // Loading state for generating keys
  const [containerVisible, setContainerVisible] = useState(false);
  const [webhookdata, setWebhookData] = useState({
    webhookUrl: "",
  });

  const [isWebhookCreated, setIsWebhookCreated] = useState(false); // Track webhook creation status

  // Using useCallback to memoize the functions for useEffect dependency
  const getApikeys = useCallback(() => {
    setLoading(true); // Start loading
    apikeyService
      .generateKey()
      .then((result) => {
        if (result && result.data.data && result.data.success) {
          const keys = result.data.data;
          setData(keys);

          // Save the keys locally
          storage.save("consumerSecret", keys.consumerSecret);
          storage.save("consumerKey", keys.consumerKey);
          storage.save("sandboxSecret", keys.sandboxSecret);
          storage.save("sandboxKey", keys.sandboxKey);
        } else {
          setData({});
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          logout();
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }, [logout]);

  const getWebhooks = useCallback(() => {
    webhookService
      .getWebhook()
      .then((result) => {
        if (result && result.data.data && result.data.success) {
          setWebhookData(result.data.data[0]);
          setIsWebhookCreated(result.data.data.length > 0); // Check if webhook already exists
        } else {
          setWebhookData({});
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          logout();
        }
      });
  }, [logout]);

  const getKycStatus = useCallback(() => {
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          const kycStatus = result.data.business.BaasKyc.status;

          if (kycStatus === "approved") {
            setContainerVisible(true);
          } else {
            setContainerVisible(false);
          }
        } else {
          toast.error("Failed to fetch KYC status");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      });
  }, [logout]);

  useEffect(() => {
    getWebhooks();
    getKycStatus();
  }, [getWebhooks, getKycStatus]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  const createWebhook = () => {
    const input = {
      webhookUrl: webhookdata?.webhookUrl,
      environment: "test",
    };

    webhookService
      .addWebhook(input)
      .then((result) => {
        if (result && result.data.data && result.data.success) {
          toast.success(result.data.message);
          storage.save("webhookUrl", webhookdata.webhookUrl); // Save the webhook URL locally
          setIsWebhookCreated(true); // Set webhook creation status to true
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.message ?? error?.response?.data?.message);
      });
  };

  return (
    <div className="py-4 flex flex-col space-y-12 px-4 md:px-12 mx-auto">
      {containerVisible ? (
        <div className="flex flex-col space-y-4">
          <p className="text-md text-secondary">API Configuration - Live Mode</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <p className="text-md">Consumer Secret Key: </p>
            <div className="flex flex-col space-y-3">
              <div className="flex flex-row items-center space-x-3">
                <input
                  type="text"
                  className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3 focus:border-primary"
                  value={data?.consumerSecret}
                  onChange={(e) => {
                    setData({ ...data, consumerSecret: e.target.value });
                  }}
                  disabled
                />
                <FaCopy
                  className="cursor-pointer text-primary text-2xl"
                  onClick={() => copyToClipboard(data.consumerSecret)}
                />
              </div>
              <p
                onClick={getApikeys}
                className={`py-3 text-primary cursor-pointer ${loading ? "opacity-50" : ""}`}
              >
                {loading ? "Generating..." : "Generate Key"}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p className="text-md">Consumer Key: </p>
            <div className="flex flex-col space-y-3">
              <div className="flex flex-row items-center space-x-3">
                <input
                  type="text"
                  className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3 focus:border-primary"
                  value={data?.consumerKey}
                  onChange={(e) => {
                    setData({ ...data, consumerKey: e.target.value });
                  }}
                  disabled
                />
                <FaCopy
                  className="cursor-pointer text-primary text-2xl"
                  onClick={() => copyToClipboard(data.consumerKey)}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p className="text-md">Webhook Url: </p>
            <div className="flex flex-col space-y-3">
              <div className="flex flex-row items-center space-x-3">
                <input
                  type="text"
                  className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3 focus:border-primary"
                  value={webhookdata?.webhookUrl}
                  onChange={(e) => {
                    setWebhookData({ ...webhookdata, webhookUrl: e.target.value });
                  }}
                />
                <button
                  onClick={createWebhook}
                  className={`py-3 text-primary cursor-pointer ${
                    !webhookdata.webhookUrl ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!webhookdata.webhookUrl}
                >
                  {isWebhookCreated ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
          {isWebhookCreated && (
            <div className="text-md text-primary">
              Webhook created! You can now update it.
            </div>
          )}
        </div>
      ) : (
        <p className="text-red-500">Your KYC is not approved. Live Mode configuration is unavailable.</p>
      )}

      <div className="flex flex-col space-y-4">
        <p className="text-md text-secondary">API Configuration - Test Mode</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-md">Consumer Secret Key: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3 focus:border-primary"
                value={data?.sandboxSecret}
                onChange={(e) => {
                  setData({ ...data, sandboxSecret: e.target.value });
                }}
                disabled
              />
              <FaCopy className="cursor-pointer text-primary text-2xl" onClick={() => copyToClipboard(data.sandboxSecret)} />
            </div>
            <p
              onClick={getApikeys}
              className={`py-3 text-primary cursor-pointer ${loading ? "opacity-50" : ""}`}
            >
              {loading ? "Generating..." : "Generate Key"}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <p className="text-md">Sandbox Key: </p>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-row items-center space-x-3">
              <input
                type="text"
                className="w-full lg:w-[500px] border border-gray-300 rounded-lg p-3 focus:border-primary"
                value={data?.sandboxKey}
                onChange={(e) => {
                  setData({ ...data, sandboxKey: e.target.value });
                }}
                disabled
              />
              <FaCopy className="cursor-pointer text-primary text-2xl" onClick={() => copyToClipboard(data.sandboxKey)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;

import Util from "../utils/util";
import { http } from "../utils/http";

// Function to get the server type from localStorage
const getServerType = () => localStorage.getItem("server") || "live"; // Default to 'live' if not set

// Function to get the correct base URL based on the server type
const getBaseUrl = () => {
  const serverType = getServerType();
  if (serverType === "test") {
    return process.env.REACT_APP_TEST_URL; // Use test URL from environment variable
  }
  return process.env.REACT_APP_LIVE_URL; // Use live URL from environment variable
};

let transactionService = {
  getMyTransactions: async () => {
    const url = `${getBaseUrl()}business/transactions/me`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getInwardTransactions: async (page) => {
    const url = `${getBaseUrl()}business/transactions/collections?limit=${Util.limit}&page=${page}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getInwardTransactionsByStatus: async (status, page) => {
    const url = `${getBaseUrl()}business/transactions/collections?limit=${Util.limit}&page=${page}&status=${status}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getACollectionTransaction: async (creditAccountNumber, page) => {
    const url = `${getBaseUrl()}business/transactions/collections?limit=${Util.limit}&page=${page}&creditAccountNumber=${creditAccountNumber}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getAllTransactions: async (page) => {
    const url = `${getBaseUrl()}business/transactions/me?limit=${Util.limit}&page=${page}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getOutwardTransactions: async (page) => {
    const url = `${getBaseUrl()}business/transactions/me?limit=${Util.limit}&page=${page}&type=debit`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getOutwardTransactionsByStatus: async (status, page) => {
    const url = `${getBaseUrl()}transactions/me?limit=${Util.limit}&status=${status}&page=${page}&type=debit`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getTransactionsByDate: async (startDate, endDate) => {
    const url = `${getBaseUrl()}business/transactions/exports?startDate=${startDate}&endDate=${endDate}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getTransactionsByReference: async (reference) => {
    const url = `${getBaseUrl()}business/transactions/reference/${reference}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getCollectionByReference: async (reference) => {
    const url = `${getBaseUrl()}business/transactions/collections/${reference}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getCollectionsByDate: async (startDate, endDate) => {
    const url = `${getBaseUrl()}business/transactions/collections/exports?startDate=${startDate}&endDate=${endDate}`;
    return await http.get(url, Util.getRegisterHeaders());
  },
  getACollectionByDate: async (startDate, endDate, creditAccountNumber) => {
    const url = `${getBaseUrl()}business/transactions/collections/exports?startDate=${startDate}&endDate=${endDate}&creditAccountNumber=${creditAccountNumber}`;
    return await http.get(url, Util.getRegisterHeaders());
  }
};

export { transactionService };

import React from 'react';
import WelcomeBanner from './components/WelcomeBanner';
import AccountDetails from './components/AccountDetails';
import Card from './components/Card'; 
import ChartContainer from './components/ChartContainer'; 
import RecentTransactionsTable from './components/RecentTransactionsTable';

const Blank = () => {
  return (
    <div className="px-2 py-12">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-24 mb-12">
        <div className="lg:col-span-2">
          <WelcomeBanner />
        </div>
        <div>
          <AccountDetails />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex flex-col space-y-4 md:col-span-1">
          <Card 
            icon="/balance.svg"  // SVG format for better quality
            heading="Available Balance" 
            description="Current available balance" 
            balance="₦1,200" 
          />
          <Card 
            icon="/ledger.svg"  // SVG format for better quality
            heading="Ledger Balance" 
            description="Total ledger balance" 
            balance="₦1,500" 
          />
        </div>
        <div className="md:col-span-2">
          <ChartContainer />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
        <div className="md:col-span-2">
          <RecentTransactionsTable />
        </div>
        <div className="md:col-span-1">
          <Card 
            icon="/spent.svg"  // SVG format for better quality
            heading="Total Spent" 
            description="Total amount spent" 
            balance="₦300" 
          />
        </div>
      </div>
    </div>
  );
};

export default Blank;

import React from 'react';

// Import SVGs as normal image sources
import approvedIcon from '../../../assets/icons/approved.svg';
import declinedIcon from '../../../assets/icons/declined.svg';

const RecentTransactionsTable = () => {
  const transactions = [
    { id: 1, date: '2024-09-01', description: 'Payment received', amount: '$100', status: 'approved' },
    { id: 2, date: '2024-09-02', description: 'Payment sent', amount: '$50', status: 'declined' },
    { id: 3, date: '2024-09-03', description: 'Subscription fee', amount: '$30', status: 'approved' },
    { id: 4, date: '2024-09-04', description: 'Transfer to savings', amount: '$200', status: 'approved' },
  ];

  const getStatusIcon = (status) => {
    return status === 'approved' ? approvedIcon : declinedIcon;
  };

  return (
    <div className="bg-white shadow-xl rounded-lg p-6">
      <h3 className="text-lg font-bold text-[#003022] mb-4">Recent Transactions</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-sm font-semibold text-[#003022] uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-[#003022] uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-[#003022] uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-[#003022] uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr 
                key={transaction.id} 
                className="border-b hover:bg-gray-200 hover:font-semibold hover:shadow-inner transition duration-200 ease-in-out">
                <td className="px-6 py-4 whitespace-nowrap text-[#003022]">{transaction.date}</td>
                <td className="px-6 py-4 whitespace-nowrap text-[#003022]">{transaction.description}</td>
                <td className="px-6 py-4 whitespace-nowrap text-[#003022]">{transaction.amount}</td>
                <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                  <img
                    src={getStatusIcon(transaction.status)}
                    alt={transaction.status}
                    className="w-6 h-6"
                  />
                  <span
                    className={`font-semibold ${
                      transaction.status === 'approved' ? 'text-green-600' : 'text-red-600'
                    }`}
                  >
                    {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentTransactionsTable;

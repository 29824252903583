import blank from "pages/blankRoute/blank";

const blankRoute = [
  {
    path: '/blank-dashboard',
    component: blank,
  },
  // Add more KYC-related routes here if needed
];

export default blankRoute;

import React, { useState, useEffect } from "react";
import { FiEye, FiCopy, FiEyeOff} from "react-icons/fi";
import { accountService } from "../../../services/account.service";
import MainCard from "../../../components/cards/MainCard";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../../../usehook/useAuth";

const DashboardHeader = () => {
  // const IMG_PATH = "/img/";
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [isLedgerBalanceVisible, setIsLedgerBalanceVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { wallet } = useAuth();

  const getAccountInfo = () => {
    setLoading(true);

    accountService
      .getMyAccount()
      .then((result) => {
        if (result && result.data && result.data.success) {
          setData(result.data.data);
          wallet(result.data.data);
        } else {
          setData({});
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          // logout()
        }
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  const handleToggleLedgerBalanceVisibility = () => {
    setIsLedgerBalanceVisible(!isLedgerBalanceVisible);
  };

  const handleCopyAccountNumber = () => {
    console.log("Copying account number:", data?.accountNumber);
    const textArea = document.createElement('textarea');
    textArea.value = data?.accountNumber; // Copy account number from data
    console.log("Text to copy:", textArea.value);
  
    // Append the text area to the document
    document.body.appendChild(textArea);
  
    // Select the text in the text area
    textArea.select();
  
    // Execute the copy command
    document.execCommand('copy');
  
    // Remove the text area from the document
    document.body.removeChild(textArea);
  
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <MainCard>
          <div className="flex flex-col space-y-4 p-3">
            <p className="font-bold">Account Details</p>
           
            {loading ? (
              <ClipLoader color="#00BC74" size={30} data-testid="loader" />
            ) : (
              <div className="flex flex-row justify-between">
                <div className="flex flex-col space-y-2">
                {isCopied && (
        <div className=" text-primary p-2 rounded-md">
         <p>Copied!</p>
        </div>
      )}
                    <p className="text-sm md:text-md text-secondary grid grid-cols-2 gap-4">
                      <span>Account Number:</span>
                      <div className="flex space-x-2">
                      <span className="text-dark font-semibold">{data?.accountNumber}</span>
                      <FiCopy size={15} className="text-primary cursor-pointer mt-auto" onClick={handleCopyAccountNumber} />
                      </div>
                    </p>
                   
                 
                  <p className="text-sm md:text-md text-secondary grid grid-cols-2 gap-4">
                    <span>Account Name:</span>
                    <span className="text-dark font-semibold">{data?.accountName}</span>
                  </p>
                  <p className="text-sm md:text-md text-secondary grid grid-cols-2 gap-4">
                    <span>Bank Name:</span>
                    <span className="text-dark font-semibold">BellBank MFB</span>
                  </p>
                </div>
                {/* <RiAccountPinCircleLine size={30} className="text-primary" /> */}
              </div>
            )}
          </div>
        </MainCard>
        <MainCard>
          <div className="flex flex-col space-y-4 p-3">
            <p className="font-bold">Available Balance</p>
            {loading ? (
              <ClipLoader color="#00BC74" size={30} data-testid="loader" />
            ) : (
              <div className="flex flex-row justify-between">
                <p className="text-lg md:text-2xl font-bold">
                  {!isBalanceVisible ? `₦ ${data?.availableBalance ?? 0.00}` : "*****"}
                </p>
               {isBalanceVisible ?( <FiEyeOff
                  size={20}
                  className="text-primary cursor-pointer"
                  onClick={handleToggleBalanceVisibility}
                />):( <FiEye
                  size={20}
                  className="text-primary cursor-pointer"
                  onClick={handleToggleBalanceVisibility}
                />)}
              </div>
            )}
          </div>
        </MainCard>
        <MainCard>
          <div className="flex flex-col space-y-4 p-3">
            <p className="font-bold">Ledger Balance</p>
            {loading ? (
              <ClipLoader color="#00BC74" size={30} data-testid="loader" />
            ) : (
              <div className="flex flex-row justify-between">
                <p className="text-lg md:text-2xl font-bold">
                  {!isLedgerBalanceVisible ? `₦ ${data?.ledgerBalance ?? 0.00}` : "*****"}
                </p>
                
                {isLedgerBalanceVisible  ?( <FiEyeOff
                  size={20}
                  className="text-primary cursor-pointer"
                  onClick={handleToggleLedgerBalanceVisibility}
                />):( <FiEye
                  size={20}
                  className="text-primary cursor-pointer"
                  onClick={handleToggleLedgerBalanceVisibility}
                />)}
              </div>
            )}
          </div>
        </MainCard>
      </div>
      
    </>
  );
};

export default DashboardHeader;

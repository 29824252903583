import React, { useState } from 'react';
import Navbar from '../pages/components/navbar/navbar1';
import Sidebar from '../pages/components/sidebar/sidebar1';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainLayout2 = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Set default state to false

  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  
  return (
    <div className="app-layout-classic w-full h-full flex overflow-x-hidden">
     <div className="">
     <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
     </div>


      <div className={`flex flex-col w-full h-full overflow-hidden ${isSidebarOpen ? "md:ml-60" : "ml-0"}`}>
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="">
          <ToastContainer />
          <div className={`w-full  px-5 ${isSidebarOpen ? "w-full" : "max-w-[1200px] mx-auto"}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayout2.propTypes = {
  children: PropTypes.node
};

export default MainLayout2;

import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { kycService } from '../../services/kyc.service';
import { uploadService } from '../../services/fileUpload.service';
import { authService } from "services/auth.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaBuilding, FaUser, FaFile } from 'react-icons/fa'; // Icons for steps

const StepIndicator = ({ currentStep }) => {
  const steps = [
    { title: 'Business Profile', description: 'Enter your business details', icon: <FaBuilding /> },
    { title: 'Business Details', description: 'Provide director details', icon: <FaUser /> },
    { title: 'KYC Documents', description: 'Upload necessary documents', icon: <FaFile /> }
  ];

  return (
    <div className="w-72 mr-10 p-5 bg-[#003022] rounded-lg shadow-lg text-white">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`flex items-center mb-5 p-4 rounded-lg ${index === currentStep ? 'bg-[#00BC74]' : 'bg-[#003022]'} text-white`}
        >
          <div className="w-12 h-12 mr-4 bg-[#00BC74] rounded-full flex items-center justify-center text-2xl">
            {step.icon}
          </div>
          <div>
            <div className="text-lg font-semibold">{step.title}</div>
            <div className="text-sm text-gray-300">{step.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const KYCForm = () => {
  const [kycSuccess, setKycSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [directors, setDirectors] = useState([
    {
      firstname: '',
      middlename: '',
      surname: '',
      designation: '',
      email: '',
      phone: '',
      bvn: '',
      dateOfBirth: '',
      identityDocument: null,
    },
  ]);

  const handleInputChange = (index, field, value) => {
    const newDirectors = [...directors];
    newDirectors[index][field] = value;
    setDirectors(newDirectors);
  };

  const addDirector = () => {
    setDirectors([
      ...directors,
      {
        firstname: '',
        middlename: '',
        surname: '',
        designation: '',
        email: '',
        phone: '',
        bvn: '',
        dateOfBirth: '',
        identityDocument: null,
      },
    ]);
  };

  const { control, register, handleSubmit, getValues } = useForm({
    defaultValues: {
      kyc: { bn: '', rc: '', tin: '' },
      documents: [{ name: '', file: null }],
      directors: directors, // Set the directors state here
    },

    
  });

  const { fields: documentFields, append: addDocument } = useFieldArray({
    control,
    name: 'documents',
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [fileUrls, setFileUrls] = useState([]);

  const getProfile = () => {
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          setUserData(result.data.business);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response?.message ?? error.response?.data?.message ?? 'Error fetching profile'
        );
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleFileUpload = async (file, name) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const uploadResponse = await uploadService.uploadFile(formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress({ [name]: progress }); // Track the upload progress for each file
        },
      });
      
      if (uploadResponse.data && uploadResponse.data.success && uploadResponse.data.data) {
        const fileURL = uploadResponse.data.data.url;
        toast.success('File uploaded successfully.');
        setFileUrls((prevFileUrls) => {
          const existingFileIndex = prevFileUrls.findIndex((item) => item.name === name);
          if (existingFileIndex !== -1) {
            const updatedFileUrls = [...prevFileUrls];
            updatedFileUrls[existingFileIndex] = { name, url: fileURL };
            return updatedFileUrls;
          } else {
            return [...prevFileUrls, { name, url: fileURL }];
          }
        });
        return { name, url: fileURL }; // Return both name and URL
      } else {
        throw new Error(uploadResponse.data.message || 'File upload failed');
      }
    } catch (error) {
      toast.error(error.response?.data.message ?? error.message);
      return { name, url: '', error: error.message }; // Return error details
    } finally {
      setLoading(false);
      setUploadProgress((prevProgress) => ({ ...prevProgress, [name]: 100 })); // Mark upload as complete
    }
  };
  

  const onSubmitForm = async (data) => {
    setLoading(true); // Start loading
  
    try {
      // Format the Business Documents by uploading files and generating URLs
      const formattedDocuments = await Promise.all(
        data.documents.map(async (doc, index) => {
          if (doc.file && doc.file[0]) {
            const uploadedFile = await handleFileUpload(doc.file[0], documentsList[index]);
            if (uploadedFile.url) {
              return uploadedFile; // Return the name and URL
            } else {
              throw new Error(`Failed to upload ${uploadedFile.name}`);
            }
          } else {
            return { name: documentsList[index], url: '' };
          }
        })
      );
  
      // Format the directors' information and upload identity documents
      const formattedDirectors = await Promise.all(
        directors.map(async (director) => {
          if (director.identityDocument && director.identityDocument[0]) {
            const uploadedFile = await handleFileUpload(director.identityDocument[0], director.firstname);
            if (uploadedFile.url) {
              return { ...director, identityDocumentUrl: uploadedFile.url };
            } else {
              throw new Error(`Failed to upload document for ${director.firstname}`);
            }
          } else {
            return { ...director, identityDocumentUrl: '' };
          }
        })
      );
  
      // Prepare the full KYC payload
      const formattedData = {
        kyc: data.kyc, // Other KYC-related fields
        documents: formattedDocuments, // All uploaded Business Documents
        directors: formattedDirectors, // Directors' info including identity documents
      };
  
      // Make the API call to submit KYC
      const response = await kycService.submitKyc(formattedData);
  
      // Handle the response
      if (response?.data?.success) {
        setKycSuccess(true); // Set KYC success state
        setFileUploadSuccess(true); // Set file upload success state
        console.log('KYC submission response:', response.data);
      } else {
        toast.error(response?.data?.message || 'KYC submission failed');
      }
    } catch (error) {
      console.error('Error submitting KYC:', error);
      toast.error(`Error submitting KYC: ${error.message}`);
    } finally {
      setLoading(false); // End loading
    }
  };
  

  const validateCurrentStep = () => {
    if (currentStep === 0) {
      // Check Business Profile step fields
      return (
        userData?.businessName &&
        getValues('kyc.rc') &&
        getValues('kyc.tin')
      );
    } else if (currentStep === 1) {
      // Check Directors Details fields
      return directors.every(director => 
        director.firstname && 
        director.surname && 
        director.email && 
        director.phone && 
        director.bvn && 
        director.dateOfBirth
      );
    } else if (currentStep === 2) {
      // Check Business Documents fields
      return documentFields.every(doc => doc.name && doc.file);
    }
    return true; // Default return for steps with no validation
  };
  
  const documentsList = [
    'Certificate of Incorporation',
    'Form CAC 1.1',
    'CAC 7A',
    'MEMART',
    'Licence of Operation',
    'Utility Bill'
  ];

  const nextStep = () => {
    if (validateCurrentStep()) {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 2));
    } else {
      toast.error('Please fill in all required fields.');
    }
  };
  // const nextStep = () => setCurrentStep((prevStep) => Math.min(prevStep + 1, 2));
  const prevStep = () => setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));

  return (
    <div className="flex flex-col lg:flex-row items-start pt-5 min-h-screen bg-white">
      <StepIndicator currentStep={currentStep} />

      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full max-w-full lg:max-w-3xl p-4 lg:p-10 bg-white rounded-lg shadow-lg">
        {currentStep === 0 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Business Profile</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="block">
                <label className="text-gray-700 mb-1 block">Business Name</label>
                <input
                  {...register('kyc.bn')}
                  value={userData?.businessName || ''}
                  className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                />
              </div>
              <div className="block">
                <label className="text-gray-700 mb-1 block">RC/BN</label>
                <input
                  {...register('kyc.rc')}
                  placeholder="RC Number"
                  className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                />
              </div>
              <div className="block">
                <label className="text-gray-700 mb-1 block">TIN</label>
                <input
                  {...register('kyc.tin')}
                  placeholder="TIN"
                  className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                />
              </div>
              <div className="block">
                <label className="text-gray-700 mb-1 block">Business Email</label>
                <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
                  {userData?.businessEmail || ''}
                </div>
              </div>
              <div className="block">
                <label className="text-gray-700 mb-1 block">Estimated Daily Collection</label>
                <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
                  ₦ {userData?.estimatedDailyTransactionVolume || '2500'}
                </div>
              </div>
              <div className="block">
                <label className="text-gray-700 mb-1 block">Estimated Daily Payout</label>
                <div className="p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-700">
                  {userData?.estimatedDailyTransferVolume || '20'}
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 1 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Directors Details</h3>
            {directors.map((director, index) => (
              <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Firstname</label>
                  <input
                    value={director.firstname}
                    onChange={(e) => handleInputChange(index, 'firstname', e.target.value)}
                    placeholder="Firstname"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Middlename</label>
                  <input
                    value={director.middlename}
                    onChange={(e) => handleInputChange(index, 'middlename', e.target.value)}
                    placeholder="Middlename"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Surname</label>
                  <input
                    value={director.surname}
                    onChange={(e) => handleInputChange(index, 'surname', e.target.value)}
                    placeholder="Surname"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Designation</label>
                  <input
                    value={director.designation}
                    onChange={(e) => handleInputChange(index, 'designation', e.target.value)}
                    placeholder="Designation"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Email</label>
                  <input
                    value={director.email}
                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                    placeholder="Email"
                    type="email"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Phone</label>
                  <input
                    type="number"
                    value={director.phone}
                    onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                    placeholder="Phone"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">BVN</label>
                  <input
                    value={director.bvn}
                    type="number"
                    onChange={(e) => handleInputChange(index, 'bvn', e.target.value)}
                    placeholder="BVN"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Date of Birth</label>
                  <input
                    value={director.dateOfBirth}
                    onChange={(e) => handleInputChange(index, 'dateOfBirth', e.target.value)}
                    type="date"
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                  />
                </div>
                <div className="block">
                  <label className="text-gray-700 mb-1 block">Identity Document</label>
                  <input
                    type="file"
                    accept="*/*" // Allows any file type (PDFs, images, docs, etc.)
                    onChange={(e) => handleInputChange(index, 'identityDocument', e.target.files)}
                    className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                      />
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={addDirector}
              className="bg-darkPrimary text-white p-2 rounded-md hover:bg-primary transition"
            >
              Add Director
            </button>
          </div>
        )}

        {currentStep === 2 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Upload Business Documents</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 mb-6 gap-4">
                {documentsList.map((documentName, index) => (
                  <div key={index} className="block">
                    <label className="text-gray-700 mb-1 block">{documentName}</label>
                    <input
                      {...register(`documents.${index}.file`)}
                      type="file"
                      accept="image/*"
                      className="p-3 border border-gray-300 rounded-md bg-gray-100 w-full"
                    />
                    {uploadProgress[documentName] && (
                      <div className="mt-2">
                        <span>{uploadProgress[documentName]}% uploaded</span>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div
                            className="bg-blue-500 h-2 rounded-full"
                            style={{ width: `${uploadProgress[documentName]}%` }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

            <button
              type="button"
              onClick={() => addDocument({ name: '', file: null })}
              className="bg-darkPrimary text-white p-2 rounded-md hover:bg-primary transition"
            >
              Add Document
            </button>
          </div>
        )}

        <div className="flex justify-between">
          {currentStep > 0 && (
            <button
              type="button"
              onClick={prevStep}
              className="bg-primary text-white p-2 px-8 rounded-md hover:bg-darkPrimary transition"
            >
              Previous
            </button>
          )}
          {currentStep < 2 ? (
            <button
              type="button"
              onClick={nextStep}
              className="bg-darkPrimary text-white p-2 px-8 rounded-md hover:bg-primary transition"
            >
              Next
            </button>
          ) : (
            <button
              type="submit"
              className="bg-primary text-white p-2 rounded-md hover:bg-green-600 transition"
              disabled={loading} // Disable when loading
            >
              {loading ? 'Submitting...' : 'Submit KYC'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};


export default KYCForm;

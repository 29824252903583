// import React from "react";
import PropTypes from "prop-types";
import {BsFillPlusCircleFill} from "react-icons/bs"
const Button = ({ value, onClick, bgColor, textColor, disabled }) => {
  return (
    <>
      <button
        className={`w-full flex  rounded-lg p-3 cursor-pointer ${bgColor} ${textColor}`}
        onClick={onClick}
        disabled={disabled} // Apply the disabled prop to the button element
      >
        <BsFillPlusCircleFill size={20} style={{ marginRight: "5px" }} />
        {value}
      </button>
    </>
  );
};

Button.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  disabled: PropTypes.bool, // Add the disabled prop type
};

Button.defaultProps = {
  value: "",
  bgColor: "bg-primary",
  textColor: "text-white",
  disabled: false, // Set a default value for the disabled prop if none is provided
};

export default Button;

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import DashboardBody from './components/DashboardBody';
import DashboardHeader from './components/DashboardHeader';
import useAuth from 'usehook/useAuth';
import { authService } from 'services/auth.service';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const { logout } = useAuth();
  const [kycStatus, setKycStatus] = useState(null); // State to store KYC status
  const [loading, setLoading] = useState(true);

  // Fetch the KYC status via API call
  const getKycStatus = useCallback(() => {
    setLoading(true); // Set loading to true while fetching
    authService
      .getProfile()
      .then((result) => {
        if (result && result.data.success) {
          const kycStatus = result.data.business.BaasKyc.status;
          setKycStatus(kycStatus); // Set the KYC status in the state
        } else {
          toast.error('Failed to fetch KYC status');
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401) {
          // Handle unauthorized status, perhaps by logging out
          logout();
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading after the API call
      });
  }, []);

  useEffect(() => {
    getKycStatus(); // Call the API on component mount
  }, [getKycStatus]);

  return (
    <div className="">
      <div className="flex flex-col space-y-4 py-6">
        <div className="flex flex-col md:flex-row space-y-8 lg:justify-between py-4">
          <div>
            <h3 className="text-dark text-2xl font-bold capitalize">
              Dashboard
            </h3>
          </div>

          {/* Show KYC Card based on the KYC status */}
          {!loading && kycStatus === 'not_submitted' && (
            <div className="w-full md:max-w-[500px] h-full px-4 py-2 bg-white rounded-lg">
              <p className="flex flex-col lg:flex-row text-md font-semibold text-dark">
                <span>Complete your onboarding process by clicking</span>
                <span className="text-red px-2">
                  <Link to="/kyc">Complete your KYC</Link>
                </span>
              </p>
            </div>
          )}

          {!loading && kycStatus === 'pending' && (
            <div className="w-full md:max-w-[500px] h-full px-4 py-2 bg-white rounded-lg">
              <p className="flex flex-col lg:flex-row text-md font-semibold text-dark">
                <span>Your KYC is under review. Please wait for approval.</span>
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-col space-y-8">
          <DashboardHeader />
          <DashboardBody />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

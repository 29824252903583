import React, { useState } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { FiChevronDown } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "../navbar/components/menu";
import Header from "./header";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ICONS_PATH = "/icons";

const menuItems = [
  // {
  //   title: "Onboarding",
  //   type: "item",
  //   url: "/onboarding",
  //   icon: `${ICONS_PATH}/kyc.svg`,
  // },
  {
    title: "Dashboard",
    type: "item",
    url: "/dashboard",
    icon: `${ICONS_PATH}/dashboard.svg`,
  },
  {
    title: "Transactions",
    type: "item",
    url: "/business-transactions",
    icon: `${ICONS_PATH}/transaction.svg`,
  },
  {
    title: "Collection",
    type: "item",
    url: "/collections-history",
    icon: `${ICONS_PATH}/transaction.svg`,
  },
  {
    title: "Pay Out",
    type: "Collapse",
    icon: `${ICONS_PATH}/transfer.svg`,
    subMenu: [
      {
        title: "New Payout",
        type: "item",
        url: "/interbank-transfer",
        icon: `${ICONS_PATH}/transfer.svg`,
      },
      {
        title: "Pay Out History",
        type: "item",
        url: "/outward-history",
        icon: `${ICONS_PATH}/history.svg`,
      },
    ],
  },
  {
    title: "Accounts",
    type: "item",
    url: "/virtual-account",
    icon: `${ICONS_PATH}/virtual-acct.svg`,
  },
  // {
  //   title: "Webhook Notification",
  //   type: "item",
  //   url: "/webhook",
  //   icon: `${ICONS_PATH}/weebhook.svg`,
  // },
  // {
  //   title: "Test Fund Account",
  //   type: "item",
  //   url: "/fund-test",
  //   icon: `${ICONS_PATH}/coin.svg`,
  // },
  {
    title: "Settings",
    type: "item",
    url: "/settings",
    icon: `${ICONS_PATH}/setting.svg`,
  },
];

// const testFundAccountItem = {
//   title: "Test Fund Account",
//   type: "item",
//   url: "/fund-test",
//   icon: `${ICONS_PATH}/coin.svg`,
// };

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const userState = useSelector((state) => state.user);
  const kycStatus = userState.KycBusinesses && userState.KycBusinesses[0] ? userState.KycBusinesses[0].status : null;
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCollapseType, setSelectedCollapseType] = useState(null);
  // const server = JSON.parse(localStorage.getItem("server"));

  const handleItemClick = (title, type) => {
    if (type === "Collapse" && selectedItem === title) {
      setSelectedCollapseType(null);
      setSelectedItem(null);
    } else if (type === "Collapse") {
      setSelectedItem(title);
      setSelectedCollapseType(title);
    } else {
      setSelectedItem((prevSelectedItem) =>
        prevSelectedItem === title ? null : title
      );
    }
  };

  return (
    <div
      className={`w-52 md:w-60 fixed bg-white h-screen shadow-sm ${
        isSidebarOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex flex-row space-x-4 px-3">
        <div className="md:p-4">
          <Header />
        </div>
        <div className="md:hidden block mt-6">
          <MenuIcon toggleSidebar={toggleSidebar} />
        </div>
      </div>
      <div className="py-6 px-2">
        <ul className="flex flex-col space-y-3 px-4 md:px-4">
          {menuItems.map((item, index) => {
            if (kycStatus === "approved" && item.url === "/onboarding") {
              return null;
            }

            if (kycStatus === "not_submitted" || kycStatus === "pending") {
              if (item.type === "Collapse" && item.subMenu) {
                return (
                  <li
                    key={index}
                    className="text-md font-regular text-gray-300"
                  >
                    <div
                      className={
                        selectedItem === item.title
                          ? "w-full h-full py-2 rounded-lg text-dark"
                          : "w-full h-full py-2 bg-transparent hover:px-3 hover:bg-slate-200 hover:rounded-lg text-secondary"
                      }
                      onClick={() => handleItemClick(item.title, "Collapse")}
                    >
                      <div className="flex flex-row items-center justify-between cursor-pointer">
                        <div className="flex flex-row space-x-3">
                          <span
                            className={`${
                              pathname === item.url
                                ? "text-dark"
                                : "text-secondary"
                            }`}
                          >
                            <img src={item.icon} alt="sidebar-icons" />
                          </span>
                          <span
                            className={`${
                              pathname === item.url
                                ? "text-dark text-md font-regular"
                                : "text-secondary text-md font-regular"
                            }`}
                          >
                            {item.title}
                          </span>
                        </div>
                        <div
                          onClick={() =>
                            handleItemClick(item.title, "Collapse")
                          }
                          className=""
                        >
                          {selectedCollapseType === item.title ? (
                            <FiChevronDown className="w-5 h-5" />
                          ) : (
                            <MdOutlineChevronRight className="w-5 h-5" />
                          )}
                        </div>
                      </div>
                    </div>
                    {item.subMenu && selectedItem === item.title && (
                      <ul className="ml-4">
                        {item.subMenu.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className={
                              pathname === subItem.url
                                ? "w-full h-full py-2 rounded-lg text-dark active"
                                : "w-full h-full py-2 bg-transparent hover:px-3 hover:bg-slate-200 hover:rounded-lg hover:text-dark text-secondary"
                            }
                          >
                            <div className="flex flex-row items-center space-x-2 cursor-pointer">
                              <span
                                className={`${
                                  pathname === subItem.url
                                    ? "text-dark"
                                    : "text-secondary"
                                }`}
                              >
                                <img src={subItem.icon} alt="sidebar-icons" />
                              </span>
                              <span
                                className={`${
                                  pathname === subItem.url
                                    ? "text-dark text-md font-regular"
                                    : "text-secondary text-md font-regular"
                                }`}
                              >
                                {subItem.title}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              } else if (item.type === "item") {
                return (
                  <li
                    key={index}
                    className={
                      pathname === item.url
                        ? "w-full h-full py-2 text-dark hover:px-3 hover:bg-slate-200 hover:rounded-lg activesin"
                        : "w-full h-full py-2 bg-transparent hover:px-3 hover:bg-slate-200 hover:rounded-lg hover:text-primary text-primary"
                    }
                  >
                    <div className="flex flex-row items-center space-x-2 cursor-pointer">
                      <span
                        className={`${
                          pathname === item.url ? "text-dark" : "text-secondary"
                        }`}
                      >
                        <img src={item.icon} alt="sidebar-icons" />
                      </span>
                      <span
                        className={`${
                          pathname === item.url
                            ? "text-dark text-md font-regular"
                            : "text-secondary text-md font-regular"
                        }`}
                      >
                        {item.title}
                      </span>
                    </div>
                  </li>
                );
              }
            } else {
              if (item.type === "Collapse" && item.subMenu) {
                return (
                  <li
                    key={index}
                    className="text-md font-regular text-gray-300"
                  >
                    <div
                      className={
                        selectedItem === item.title
                          ? "w-full h-full py-2 rounded-lg text-dark"
                          : "w-full h-full py-2 bg-transparent hover:px-3 hover:bg-slate-200 hover:rounded-lg text-secondary"
                      }
                      onClick={() => handleItemClick(item.title, "Collapse")}
                    >
                      <div className="flex flex-row items-center justify-between cursor-pointer">
                        <div className="flex flex-row space-x-3">
                          <span
                            className={`${
                              pathname === item.url
                                ? "text-dark"
                                : "text-secondary"
                            }`}
                          >
                            <img src={item.icon} alt="sidebar-icons" />
                          </span>
                          <span
                            className={`${
                              pathname === item.url
                                ? "text-dark text-md font-regular"
                                : "text-secondary text-md font-regular"
                            }`}
                          >
                            {item.title}
                          </span>
                        </div>
                        <div
                          onClick={() =>
                            handleItemClick(item.title, "Collapse")
                          }
                          className=""
                        >
                          {selectedCollapseType === item.title ? (
                            <FiChevronDown className="w-5 h-5" />
                          ) : (
                            <MdOutlineChevronRight className="w-5 h-5" />
                          )}
                        </div>
                      </div>
                    </div>
                    {item.subMenu && selectedItem === item.title && (
                      <ul className="ml-4">
                        {item.subMenu.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className={
                              pathname === subItem.url
                                ? "w-full h-full py-2 rounded-lg text-dark active"
                                : "w-full h-full py-2 bg-transparent hover:px-3 hover:bg-slate-200 hover:rounded-lg hover:text-dark text-secondary"
                            }
                          >
                            <Link
                              to={`${subItem.url}`}
                              className="flex flex-row items-center space-x-2 cursor-pointer"
                            >
                              <span
                                className={`${
                                  pathname === item.url
                                    ? "text-dark"
                                    : "text-secondary"
                                }`}
                              >
                                <img src={subItem.icon} alt="sidebar-icons" />
                              </span>
                              <span
                                className={`${
                                  pathname === item.url
                                    ? "text-dark text-md font-regular"
                                    : "text-secondary text-md font-regular"
                                }`}
                              >
                                {subItem.title}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              } else if (item.type === "item") {
                return (
                  <li
                    key={index}
                    className={
                      pathname === item.url
                        ? "w-full h-full py-2 text-dark hover:px-3 hover:bg-slate-200 hover:rounded-lg activesin"
                        : "w-full h-full py-2 bg-transparent hover:px-3 hover:bg-slate-200 hover:rounded-lg hover:text-primary text-primary"
                    }
                  >
                    <Link
                      to={`${item.url}`}
                      className="flex flex-row items-center space-x-2 cursor-pointer"
                    >
                      <span
                        className={`${
                          pathname === item.url ? "text-dark" : "text-secondary"
                        }`}
                      >
                        <img src={item.icon} alt="sidebar-icons" />
                      </span>
                      <span
                        className={`${
                          pathname === item.url
                            ? "text-dark text-md font-regular"
                            : "text-secondary text-md font-regular"
                        }`}
                      >
                        {item.title}
                      </span>
                    </Link>
                  </li>
                );
              }
            }

            return null;
          })}
          {/* {!server && (
            <li
              className={
                pathname === testFundAccountItem.url
                  ? "w-full h-full py-2 text-dark hover:px-3 hover:bg-slate-200 hover:rounded-lg activesin"
                  : "w-full h-full py-2 bg-transparent hover:px-3 hover:bg-slate-200 hover:rounded-lg hover:text-primary text-primary"
              }
            >
              <Link
                to={`${testFundAccountItem.url}`}
                className="flex flex-row items-center space-x-2 cursor-pointer"
              >
                <span
                  className={`${
                    pathname === testFundAccountItem.url
                      ? "text-dark"
                      : "text-secondary"
                  }`}
                >
                  <img src={testFundAccountItem.icon} alt="sidebar-icons" />
                </span>
                <span
                  className={`${
                    pathname === testFundAccountItem.url
                      ? "text-dark text-md font-regular"
                      : "text-secondary text-md font-regular"
                  }`}
                >
                  {testFundAccountItem.title}
                </span>
              </Link>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import DatePickerField from "../../../components/controls/DatePicker";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transactionService } from "../../../services/transaction.service";
import * as XLSX from 'xlsx';
// import { FaTimes } from 'react-icons/fa'; // Assuming you're using react-icons for the close icon

const ExportTransaction = ({ logout }) => {
  const maxFutureMonths = 12;
  // const [isVisible, setIsVisible] = useState(true); // Add visibility state

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDateRangeChange = (dates) => {
    setDateRange({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  const [data, setData] = useState([]);

  const getTransactionsByDateRange = async () => {
    try {
      setData([]);
      const result = await transactionService.getTransactionsByDate(
        dateRange.startDate.getTime() / 1000,
        dateRange.endDate.getTime() / 1000
      );
      if (result && result.data && result.data.success === true) {
        setData(result.data.data);
      } else {
        toast.error("Error fetching transaction..");
      }
    } catch (error) {
      console.error(error);
      setData([]);
      if (error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      ...item,
      completedAt: new Date(item.completedAt * 1000).toLocaleString(),
      ...item.details, // Spread the details object
    }));

    // Remove unwanted properties
    const cleanedData = formattedData.map(
      ({
        details,
        userId,
        businessId,
        id,
        utilityType,
        stampDuty,
        category,
        completedAt,
        updatedAt,
        ...rest
      }) => rest
    );

    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "transactions");
    XLSX.writeFile(workbook, "transactions.xlsx");
  };

  useEffect(() => {
    // Download Excel if data is available
    if (data.length > 0) {
      exportToExcel();
      toast.success("Transaction statement downloaded..");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // Include 'data' as a dependency

  // Close handler
  // const handleClose = () => {
  //   setIsVisible(false); // Hides the component
  // };

  // if (!isVisible) return null; // Return nothing if not visible

  return (
    <div className="relative"> {/* Relative position for the close button */}
      <div className="flex flex-col space-y-3">
        <div className="flex justify-between items-center">
          {/* <h1>Export By Date</h1> */}
          {/* <FaTimes
            className="cursor-pointer text-red-500"
            size={24}
            onClick={handleClose} // Close button
          /> */}
        </div>
        <DatePickerField
          initialStartDate={dateRange.startDate}
          initialEndDate={dateRange.endDate}
          onDateRangeChange={handleDateRangeChange}
          maxFutureMonths={maxFutureMonths}
        />
        {/* <div className="w-full h-full border p-2 rounded-lg">
          <div className="flex flex-col space-y-4">
            You can add other elements here if needed
          </div>
        </div> */}
        <SubmitButton onClick={getTransactionsByDateRange}>Download</SubmitButton>
      </div>
    </div>
  );
};

export default ExportTransaction;

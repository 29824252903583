import React, {useState} from 'react'
import SubmitButton from '../../../../components/controls/SubmitButton';
import InputField from '../../../../components/controls/InputField';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
const PasswordForm = () => {
    const [input, setInput] = useState({
        confirmPassword: "",
        password: "",
        oldPassword: "",
      });
      const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
      const toggleOldPasswordVisibility = () => {
        setOldPasswordVisible(!oldPasswordVisible);
      };
    

      const [passwordVisible, setPasswordVisible] = useState(false);
      const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
      };
    
      const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
      const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
      };
    
      const inputHandler = (e) => {
        const name = e.target.name;
        setInput({ ...input, [name]: e.target.value });
    
        // Reset passwordError when the user changes the password input
        if (name === "newPassword" || name === "confirmPassword") {
          validatePasswordStrength(e.target.value);
        }
      };
      const [passwordError, setPasswordError] = useState("");
    
    
      const validatePasswordStrength = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
        const isValidPassword = passwordRegex.test(password);
    
        if (!isValidPassword) {
          setPasswordError(
            "Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
          );
        } else {
          setPasswordError("");
        }
      };
    const submit = () => {

    }
  return (
    <div className="flex flex-col space-y-4 py-4">
         <div>
         <InputField 
          value={input.oldPassword}
          type={oldPasswordVisible ? "text" : "password"}
          onChange={inputHandler}
          label="New Password"
          name="password"
          rightIcon={
            oldPasswordVisible ? (
              <FaRegEyeSlash size={20} onClick={toggleOldPasswordVisibility} />
            ) : (
              <FaRegEye size={20} onClick={toggleOldPasswordVisibility} />
            )
          }
        />
                </div>
                <div>
        <InputField 
          value={input.password}
          type={passwordVisible ? "text" : "password"}
          onChange={inputHandler}
          label="New Password"
          name="password"
          rightIcon={
            passwordVisible ? (
              <FaRegEyeSlash size={20} onClick={togglePasswordVisibility} />
            ) : (
              <FaRegEye size={20} onClick={togglePasswordVisibility} />
            )
          }
        />
        {passwordError && <p className="text-red-500">{passwordError}</p>}
      </div>

      <div>
        <InputField 
          value={input.confirmPassword}
          type={confirmPasswordVisible ? "text" : "password"}
          onChange={inputHandler}
          label="Confirm Password"
          name="confirmPassword"
          rightIcon={
            confirmPasswordVisible ? (
              <FaRegEyeSlash size={20} onClick={toggleConfirmPasswordVisibility} />
            ) : (
              <FaRegEye size={20} onClick={toggleConfirmPasswordVisibility} />
            )
          }
        />
      </div>

                <div>
                <SubmitButton onClick={submit} disabled={false}>Update</SubmitButton>
                </div>
    </div>
  )
}

export default PasswordForm